var host = window.location.hostname;

var baseUrl = "https://apivivedep329898.cliker.com.mx/";

function getURL() {
  if (host === "payroll.cliker.com.mx" || host === "www.payroll.cliker.com.mx") {
    baseUrl = "https://apigdth.cliker.com.mx/";
  } else if (
    host === "devmid19.pbmg.com.mx" ||
    host === "www.devmid19.pbmg.com.mx" ||
    host === "payrolldev.pbmg.com.mx" ||
    host === "www.payrolldev.pbmg.com.mx"
  ) {
    baseUrl = "https://apidevmid19.pbmg.com.mx/";
  }
  return baseUrl;
}

const GetUrlImg = {
  getURL,
};

export default GetUrlImg;
